
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmGrayTileButton from '@/components/shared/TmGrayTileButton.vue'
import type { RouteLocationRaw } from 'vue-router'

type Item = {
  icon: string;
  label: string;
  description: string;
  to?: RouteLocationRaw;
  onClick?: () => void;
}

export default defineComponent({
  name: 'GrayTileButtonsModal',
  components: {
    TmGrayTileButton,
    TmModal,
  },
  props: {
    items: {
      type: Array as PropType<Item[]>,
    },
  },
})
