
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'TmGrayTileButton',
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
  },
})
