import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b8e24f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subhead-semi-bold-16 neutral--text lh-20 mb-6px" }
const _hoisted_2 = { class: "body-text-regular-14 lh-20 distinct--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'button'), _mergeProps(_ctx.to ? { to: _ctx.to } : { type: 'button' }, { class: "tm-gray-tile-button" }), {
    default: _withCtx(() => [
      _createVNode(_component_tm_icon, {
        name: _ctx.icon,
        "custom-size": "72px",
        class: "primary--text mb-10px"
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.description), 1)
    ]),
    _: 1
  }, 16))
}