import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_gray_tile_button = _resolveComponent("tm-gray-tile-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "grayTileButtons",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "d-flex col-12 col-md-6"
          }, [
            _createVNode(_component_tm_gray_tile_button, _mergeProps({ class: "flex-grow-1" }, item), null, 16)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}